/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Image, Button } from '@swp/components'
import SiteHeader from '../../components/en/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1g6syku js-anim  --anim7 --anim-s5 bg--bottom --full" anim={"7"} name={"introduction"} animS={"5"} border={null} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 pt--10 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 pb--80 pt--80" anim={"6"} animS={"5"} style={{"maxWidth":1080}}>
              
              <Title className="title-box fs--102 w--300 lh--12 mt--08" content={"<span style='color: white'>Evelyn & Wyatt</span>"}>
              </Title>

              <Subtitle className="subtitle-box mt--10" content={"<span style='color: white'>7—27—2021, Lake Erie — Racine, WI</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--50" name={"information"} style={{"backgroundColor":"var(--color-dominant)"}} border={null} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pl--0 pr--0 flex--center" anim={"2"} animS={"3"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--style8 title-box--center w--300" content={"<span style='color: var(--color-supplementary)'>Ceremony</span>"}>
              </Title>

              <Text className="text-box lh--14" style={{"maxWidth":278}} content={"14:00<br>The church of St. John<br>Finch Street 24 12<br>Lake Erie — Racine, WI"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/128/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/128/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/128/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/128/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/128/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/128/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--25" name={"information-2"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pl--0 pr--0 flex--center" anim={"2"} animS={"3"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/128/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/128/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/128/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/128/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/128/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/128/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":560}}>
              
              <Title className="title-box title-box--style8 title-box--center w--300" style={{"maxWidth":512}} content={"<span style='color: var(--color-supplementary)'>Reception</span>"}>
              </Title>

              <Text className="text-box lh--14" style={{"maxWidth":290}} content={"16:00<br>Hotel GRAND<br>Finch Street 24 12<br>Lake Erie — Racine, WI"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1g6syku --center --parallax pb--80 pt--80" name={"information-3"} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" anim={null} animS={null} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 pb--40 pt--40" anim={"6"} animS={"5"}>
              
              <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--62" innerClassName="mt--10 mb--10" href={"/en/photogallery"} content={"<span style='color: white'>Photogallery</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--62" innerClassName="mt--10 mb--10" href={"/en/gift-registry"} content={"<span style='color: white'>Contact</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--62" innerClassName="mt--10 mb--10" href={"/en/rsvp"} content={"RSVP"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"information-4"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pl--0 pr--0 flex--center" anim={"2"} animS={"3"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box title-box--center w--300" content={"<span style='color: var(--color-dominant)'>How it began</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":416}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1" href={"/en/Information"} content={"<span style='color: var(--color-dominant)'>More</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/128/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/128/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/128/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/128/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/128/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/128/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-snvqp6 --center bg--center-h bg--bottom --parallax pb--80 pt--80" name={"information-5"} parallax={true} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/128/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--20 pt--20" style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--left mb--80 mt--80">
              
              <Title className="title-box title-box--center fs--86" content={"<span style='color: white'>We look forward to seeing you!</span>"}>
              </Title>

              <Text className="text-box text-box--center mt--10" style={{"maxWidth":554}} content={"<span style='color: white'><a href=\"https://saywebpage.com\">Create your own website</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}